import React from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailIcon from '@material-ui/icons/Email';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';

import ContactContext from '../context/Contact';
import ContactForm from '../components/contact/ContactForm';
import FadeIn from '../components/transitions/FadeIn';
import FadeInUp from '../components/transitions/FadeInUp';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Contact = () => {
    const {
        isSubmittingForm,
        setErrors,
        submitContactForm,
        updateContactState,
    } = React.useContext(ContactContext);

    React.useEffect(() => {
        // on unmount, let's reset the form state so that
        // as the user navigates to other pages and enables
        // the contact form there are no previous values
        return () => {
            setErrors({});
            // Clear out all fields but the email
            updateContactState({
                message: '',
                name: '',
                phone: '',
            });
        };  
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClick = () => {
        submitContactForm().catch(() => { });

        if (typeof window !== "undefined" && window.gtag) {
            window.gtag("event", "contact_form_button", {
                // string - required - The object that was interacted with (e.g.video)
                category: "Contact Form Button",
                // string - required - Type of interaction (e.g. 'play')
                action: "Click",
                // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                label: "Contact Form Submission",
                // number - optional - Numeric value associated with the event. (e.g. A product ID)
                value: 3
            });
        }
    }

    return (
        <Layout>
            <SEO title="Contact" description="Contact SpeedyDock for a free demo of our dry stack marina software or boat club solution." />
            <Box pb={8} pt={16} bgcolor="secondary.dark" color="primary.main">
                <Container>
                    <FadeIn>
                        <Typography variant="h3" component="h1" gutterBottom>
                            Contact
                        </Typography>
                    </FadeIn>
                </Container>
            </Box>
            <Box pb={8} pt={8}>
                <Container>
                    <Grid container spacing={9}>
                        <Grid item sm={12} md={5}>
                            <FadeInUp>
                                <Box pb={3}>
                                    <Typography variant="h5" component="h2" gutterBottom>
                                        Our contact info
                                    </Typography>
                                    <Typography gutterBottom>
                                        Contact us to schedule a free demo and learn more about how SpeedyDock can help with your dry stack marina, boat club, or e-commerce integration.
                                    </Typography>
                                </Box>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PhoneIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link href="tel://8138512627">(813) 851-2627</Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <EmailIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link href="mailto:sales@speedydock.com">sales@speedydock.com</Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <EmailIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Link href="mailto:support@speedydock.com">support@speedydock.com</Link>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </FadeInUp>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <FadeInUp>
                                <Box>
                                    <Typography variant="h5" component="h2" gutterBottom>
                                        Request a demo
                                    </Typography>
                                    <ContactForm />
                                    <Box textAlign="right" pt={2} pb={8}>
                                        {
                                            isSubmittingForm ? <CircularProgress /> : (
                                                <Button variant="contained" onClick={onClick} color="primary">
                                                    Request demo
                                                </Button>
                                            )
                                        }
                                    </Box>
                                </Box>
                            </FadeInUp>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Layout>
    );
};

export default Contact;
